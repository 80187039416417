<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import notificationMixin from 'src/mixins/notificationMixin'
import masterMixin from 'src/mixins/masterMixin'
import budgetGroupApi from 'src/apis/workplace_masters/budget_group'
import timingControlMixin from 'src/mixins/timingControlMixin'
import windowMixin from 'src/mixins/windowMixin'
import BudgetUnitCard from './BudgetUnitCard'
import ListSwitchPanel from '../ListSwitchPanel'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { ensureWorkplaceRoleGteOwner } from 'src/hooks/appRoleHook'

const api = budgetGroupApi
const displayPageName = '管理グループ'
const msgVars = {
  create: '作成',
  update: '編集',
  delete: '削除',
  update_disp_order: '表示順変更'
}

export default {
  components: {
    Draggable,
    BudgetUnitCard,
    ListSwitchPanel,
  },
  data() {
    return {
      displayPageName: displayPageName,
      isSuperAdmin: false,
      searchParams: {
        displayListDisabledItem: false,
        budgetGroup: {},
      },
      list: [],
      saveCandidate: {},
      deleteCandidate: {},
      showSaveModal: false,
      showDeleteModal: false,

      validations: this.getValidationMap(),

      dragOptions: {
        handle: '.grabbable',
        animation: 300,
        chosenClass: 'bg-color-default',
      },
      fixTable: true,
    }
  },
  computed: {
    ...mapGetters('user', {
      userId: 'id',
    }),
    ...mapGetters('workplace', {
      useCreateAndUpdateInfo: 'useCreateAndUpdateInfo',
    }),
    workplaceId() {
      return this.$route.params.workplaceId
    },
    hasList() {
      return this.list.length > 0
    },
    hasError() {
      return this.errors.any()
    },
    hasSearchParamsBudgetGroup() {
      return Object.keys(this.searchParams.budgetGroup).length > 0
    },
    rowWidth() {
      return 800 +
        Number(this.useCreateAndUpdateInfo) * 588
    }
  },
  mixins: [ notificationMixin, masterMixin, timingControlMixin, windowMixin ],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getValidationMap() {
      // 管理グループ名を254文字以上で保存するとサーバー側で「(管理グループ名)＋共有」の名前で荷主を作るときに
      // budget_units.nameが255文字を超えてDB保存時にデータ長エラーになる.
      // 管理グループ名は200文字あれば十分.
      const ruleBudgetGroupName = { required: true, max: 200 }
      const rulePercentage = {
        required: true,
        decimal: 2,
        min_value: 0,
        max_value: 100,
      }
      return {
        name: ruleBudgetGroupName,
        target_profit_rate_percent: rulePercentage,
      }
    },
    getApiParams() {
      let apiParams = {}
      if (!this.searchParams.displayListDisabledItem) {
        apiParams['is_enabled'] = true
      }
      return apiParams
    },
    clearErrors() {
      this.$validator.reset()
    },
    async getList() {
      const { data } = await api.index({
        workplaceId: this.workplaceId,
        params: this.getApiParams(),
      })
      data.forEach(e => {
        // *_rate
        e.target_profit_rate_percent = (e.target_profit_rate * 100).toFixed(2)
      })
      this.list = data
      if (Object.keys(this.searchParams.budgetGroup).length === 0 && this.hasList) {
        // to make it default selected.
        this.searchParams.budgetGroup = this.list[0]
      }
    },
    async getListWithUpdatedSearchParams(params) {
      this.searchParams.displayListDisabledItem = params.displayDisabledModel
      await this.getList()
    },
    openSaveModal(item) {
      let saveCandidate = {}
      if (!item.id) {
        saveCandidate.isNew = true
        saveCandidate.is_enabled = true
      } else {
        saveCandidate = JSON.parse(JSON.stringify(item))
      }
      this.saveCandidate = saveCandidate
      this.showSaveModal = true
    },
    closeSaveModal() {
      this.saveCandidate = {}
      this.clearErrors()
      this.showSaveModal = false
    },
    async saveItem() {
      const isValid = await this.$validator.validate()
      if (!isValid) { return }

      const opType = this.saveCandidate.isNew ? 'create' : 'update'
      // *_rate
      this.saveCandidate.target_profit_rate =
        (parseFloat(this.saveCandidate.target_profit_rate_percent) / 100).toFixed(4)
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        }
        await api[opType](reqObj)
        this.getList()
        this.closeSaveModal()
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`)
        this.reloadOpener()
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'dup_name') {
          const msg = `その管理グループ名は既に使用されています。`
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = this.saveCandidate.isNew ? 'ERR00001' : 'ERR00002'
          const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    openDeleteModal(item) {
      this.deleteCandidate = item
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.deleteCandidate = {}
      this.showDeleteModal = false
    },
    async deleteItem() {
      const opType = 'delete'
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          itemId: this.deleteCandidate.id,
        }
        await api[opType](reqObj)
        await this.getList()
        this.closeDeleteModal()
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`)
        // Select first item of the buget_group list after item is deleted.
        if (this.hasList) {
          this.searchParams.budgetGroup = this.list[0]
        } else {
          this.searchParams.budgetGroup = {}
        }
        this.reloadOpener()
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'is_undeletable') {
          const msg = '一定時間が経過したため削除できません。' +
            '無効化をおすすめします。'
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'すでに使われているマスタです。削除できません。' +
            '無効化をおすすめします。'
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00003'
          const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    isDraggable(e) {
      return true
    },
    async updateDispOrder() {
      const opType = 'update_disp_order'
      try {
        this.list.forEach((e, i) => {
          e.disp_order = i + 1
        })
        const reqObj = {
          workplaceId: this.workplaceId,
          items: this.list,
        }
        await api.bulkUpdateDispOrder(reqObj)
        this.getList()
      } catch (err) {
        const errId = 'ERR00004'
        const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
          `管理者に連絡してください。` +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    makeFuncGated() {
      this.saveItem = this.makeAsyncFuncGated(this.saveItem)
      this.deleteItem = this.makeAsyncFuncGated(this.deleteItem)
      this.updateDispOrder = this.makeAsyncFuncGated(this.updateDispOrder)
    },
    selectItem(item) {
      this.searchParams.budgetGroup = item
    },
    isSelectedItem(item) {
      if (Object.keys(this.searchParams.budgetGroup).length === 0) {
        return false
      }
      if (this.searchParams.budgetGroup.id !== item.id) {
        return false
      }
      return true
    },
  },
  created() {
    this.makeFuncGated()
  },
  async mounted() {
    setPageName(this, displayPageName)
    await this.ensureUserAndMasters()
    await ensureWorkplaceRoleGteOwner(this, this.workplaceId)
    await this.getList()
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
